import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <a href="#content" className="sr-only focusable">
          Direkt zum Seiteninhalt
        </a>
        <div id="top" className="page-wrapper">
        <header id="header" class="page-header ">
      <div class="page-header__wrapper">
        <a href="#" id="touch-menu" class="page-header__nav-button" data-icon="list"></a>
        <a href="/" title="Home" rel="home" class="page-header__site-logo">
          <img src="../../img/kenvue-logo-black-rgb.svg" class="page-header__site-logo-image" alt="Home"/>
        </a>
      </div>
      <nav class="main-menu">
        <ul class="main-menu__list" role="menubar">
          <li class="main-menu__item" role="none">
            <a href="/" class="main-menu__link" role="menuitem">Home</a>
          </li>
          <li class="main-menu__item" role="none">
            <a href="/marken" class="main-menu__link" role="menuitem">Marken</a>
          </li>
          <li class="main-menu__item active" role="none">
            <a href="/karriere" class="main-menu__link" role="menuitem">Karriere</a>
          </li>
          <li class="main-menu__item" role="none">
            <a href="/kontakt" class="main-menu__link" role="menuitem">Kontakt &amp; Service</a>
          </li>
          <li class="main-menu__item" role="none">
            <img class="top-employee-europe" src="../../img/Top_Employer_Europe_2022-1.jpg" />
          </li>
          <li class="main-menu__item" role="none">
            <img class="top-employee" src="../../img/Top_Employers_Austria_2022.jpg" />
          </li>
        </ul>
      </nav>
    </header>
          <div className="stage">
            <picture>
              <source
                media="(min-width:1920px)"
                srcSet="/img/stage/JnJ_Karriere_3400x566.jpg"
              />
              <source
                media="(min-width:1025px)"
                srcSet="/img/stage/JnJ_Karriere_1920x400.jpg"
              />
              <source
                media="(min-width:0)"
                srcSet="/img/stage/JnJ_Karriere_1024x300.jpg"
              />
              <img
                src="/img/stage/JnJ_Karriere_3400x566.jpg"
                alt="Karriere"
                className="stage__image"
              />
            </picture>
          </div>
          <main id="content" className="page-content">
            <ul className="breadcrumb">
              <li className="breadcrumb__list-item">
                <a href="/" className="breadcrumb__link">
                  Home
                </a>
              </li>
              <li className="breadcrumb__list-item">
                <span className="breadcrumb__text">&nbsp;Karriere</span>
              </li>
            </ul>
            <h1 className="page-headline">
              Jobs bei Kenvue
            </h1>
            <div className="text-module">
	    <p>Wenn Sie mehr zu den Karrieremöglichkeiten bei Kenvue erfahren möchten, besuchen Sie uns auf <a href="https://www.kenvue.com/" target="_blank">www.Kenvue.com</a></p>
	    </div>
          </main>
          <Footer></Footer>
        </div>
        <script src="/js/main.js" />
        
        <script src="https://www.google-analytics.com/analytics.js" async />
      </div>
    );
  }
}

export default Page;
