/**
 * Copyright © 2019 Johnson & Johnson
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";
import { Helmet } from "react-helmet";
import Page from "./Page";
import Gtm from "src/components/Gtm";

export default class IndexPage extends React.Component {
  render() {
    if (typeof window !== "undefined") {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: "https://github.com/facebook/react/issues/10923"
          }}
        />
      );
    }
    return (
      <React.Fragment>
        <Page />
        <Helmet>
          <html lang="de" />
          <body />
          <meta charSet="utf-8" />
          <title>Johnson &amp; Johnson GmbH: Karriere</title>
          <meta
            name="description"
            content="Der Erfolg der Johnson & Johnson GmbH ist nur mit dem Engagement jedes Einzelnen möglich. Daher ist es dem Unternehmen wichtig, ein attraktiver Arbeitgeber zu sein. "
          />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta
            property="og:title"
            content="Johnson & Johnson GmbH: Karriere"
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.kenvue.at" />
          <meta property="og:title" content="Karriere" />
          <meta
            property="og:image"
            content="https:/XXXXXXXXXX/img/meta/jnj-open-graph.jpg"
          />
          <meta
            property="og:image:url"
            content="https:/XXXXXXXXXX/img/meta/jnj-open-graph.jpg"
          />
          <meta
            property="og:image:secure_url"
            content="https:/XXXXXXXXXX/img/meta/jnj-open-graph.jpg"
          />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:image:type" content="image/jpeg" />
          <link rel="apple-touch-icon" href="/img/meta/apple-touch-icon.png" />
          <link
            rel="icon"
            type="image/png"
            sizes="192x192"
            href="/img/meta/android-icon-192x192.png"
          />
          <link
            rel="icon"
            type="image/png"
            href="/img/meta/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            href="/img/meta/favicon-16x16.png"
          />
          <meta name="theme-color" content="#ffffff" />
          <link rel="stylesheet" href="/css/main.min.css" />
          
          
        </Helmet>
        <Gtm />
        ;
      </React.Fragment>
    );
  }
}
